import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditSingleSelectCell,
  useGridApiContext,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';

import axiosInstance from '../../axiosInstance';
import { makeStyles } from '@mui/styles';


function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    //const EventID = randomId();
    const EventID =randomId();// 0;
    setRows((oldRows) => [
      ...oldRows,
      { EventID,CourseName:'', SubjectName: '', EventName:'', EventDate:'', Duration:'', SecretCode:'', 
        TotalMarks:'', TotalQuestion:'', VivaTotalQuestions:'', VivaDuration:'', Deleted:false, IsActive:false
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [EventID]: { mode: GridRowModes.Edit, fieldToFocus: 'CourseName' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add Event
      </Button>
    </GridToolbarContainer>
  );
}

function CourseColumnEditComponent(props) {
    const apiRef = useGridApiContext();
    console.log(props)
    const handleValueChange = async () => {
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: 'SubjectName',
        value: '',
      });
      
    };
  
    return (
      <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />
    );
  }
export default function AddEvent() {
  
  const initialRef = React.useRef(true);
  const [courses, setCourses] = React.useState([]);
  const [subjects, setSubjects] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [TotalCount, setTotalCount] = React.useState(0);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  React.useEffect(() => {
    console.log(initialRef.current);
    if (initialRef.current) {
        getCouseAndSubjectList();
    }
    initialRef.current = false;
    getDataList();
  }, [paginationModel]);
  function getCouseAndSubjectList(){
    axiosInstance.get('Common/GetSubjectList').then((response)=>{
      console.log('GetSubjectList');
      console.log(response.data);
      setSubjects(response.data)
     });
     axiosInstance.get('Common/GetCourseList').then((response)=>{
      console.log('GetCourseList');
      console.log(response.data);
      setCourses(response.data)
     });
  }
  function getDataList(){
    axiosInstance.get('Event/GetEventList/?PageSize='+ paginationModel.pageSize +'&PageIndex=' +  (parseInt(paginationModel.page)+1)).then((response)=>{
        console.log(response.data);
        console.log(response.data.TotalCount)
        setRows(response.data.Data)
        setTotalCount(response.data.TotalCount)
       });
  }
  const handleEditClick = (EventID) => () => {
    console.log(rows);
    console.log(EventID)
    setRowModesModel({ ...rowModesModel, [EventID]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (EventID) => () => {
    setRowModesModel({ ...rowModesModel, [EventID]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (EventID) => () => {
    //console.log(rows);
    console.log(EventID)
    if(!Number.isInteger(EventID)){
        setRows(rows.filter((row) => row.EventID !== EventID));
    }
  };

  const handleCancelClick = (EventID) => () => {
    setRowModesModel({
      ...rowModesModel,
      [EventID]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.EventID === EventID);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.EventID !== EventID));
    }
  };
  const validateRows = (row) => {
    
    if (row.CourseName.trim() == '') {
      alert("Please select course");
      return false;
    }
    if (row.SubjectName.trim() == '') {
        alert("Please select Subject");
        return false;
    }
    if (row.EventDate == null || row.EventDate == '') {
      alert("Please enter Event date");
      return false;
    }
    console.log(row.Duration)
    if (row.Duration == '') {
        alert("Please enter Duration");
        return false;
    }
    // let newcourse = courses.filter(function (el) {return el.CourseName==row.CourseName});
    // let newcycle = cycles.filter(function (el) {return el.CycleDescription==row.CycleDescription});
    // console.log(newcourse[0].CourseId)
    // row.CourseID=newcourse[0].CourseId;
    // row.CycleID=newcycle[0].CycleId;
    // console.log(row)
    // if (row.Name.trim() == '') {
    //   alert("Please enter name");
    //   return false;
    // }
    return true;
    
  };
  // const validateRows = (row) => {
    
  //   // rows.forEach((row) => {
  //   //   console.log(row)
  //   //   if (!row.Name) newInvalidCells[row.EventID] = { ...newInvalidCells[row.EventID], Name: true };
  //   //   //if (!row.email) newInvalidCells[row.id] = { ...newInvalidCells[row.id], email: true };
  //   // });
  //   const newInvalidCells = {};
  //   if (!row.Name) newInvalidCells[row.EventID] = { ...newInvalidCells[row.EventID], Name: true };
  //   setInvalidCells(newInvalidCells);
  //   console.log(invalidCells)
  // };
  const processRowUpdate = (newRow) => {
    //validateRows(newRow);
    //const isValid = validateRow(newRow);
    const isValid = validateRows(newRow);
    if (isValid) {
        newRow.error = false;
        //const updatedRow = { ...newRow, isNew: false };
        const updatedRow = { ...newRow,EventID:Number.isInteger(newRow.EventID)?newRow.EventID:0};
        setRows(rows.map((row) => (row.EventID === newRow.EventID ? updatedRow : row)));
        //console.log(updatedRow);
        let newcourse = courses.filter(function (el) {return el.CourseName==newRow.CourseName});
        let newsubject = subjects.filter(function (el) {return el.SubjectName==newRow.SubjectName && el.CourseName==newcourse[0].CourseName});
        updatedRow.CourseID=newcourse[0].CourseId;
        updatedRow.SubjectID=newsubject[0].SubjectId;
        console.log(updatedRow)
        
        var data={
            "EventID":updatedRow.EventID,
            "EventName":updatedRow.EventName,
            "EventDate":updatedRow.EventDate,
            "CourseID":updatedRow.CourseID,
            "SubjectID":updatedRow.SubjectID,
            "Duration":updatedRow.Duration,
            "SecretCode":updatedRow.SecretCode,
            "TotalMarks":updatedRow.TotalMarks,
            "TotalQuestion":updatedRow.TotalQuestion,
            "VivaTotalQuestions":updatedRow.VivaTotalQuestions,
            "VivaDuration":updatedRow.VivaDuration,
            "Deleted":updatedRow.Deleted,
            "IsActive":updatedRow.IsActive
        };
        console.log(data)
        axiosInstance.post('Event/AddEventMaster',data,
        ).then((response)=>{
            //console.log(response.data);
            if(response.data.Status){
                alert(response.data.Message);
            }
            else{
                alert(response.data.Message)
            }
            getDataList();
        });
        
        return updatedRow;
    } else {
      newRow.error = true; // Mark as invalid
      alert('Required field is empty!'); // Error handling
      const updatedRow = { ...newRow,isNew:true};
      setRows(rows.map((row) => (row.EventID === newRow.EventID ? updatedRow : row)));
      return updatedRow; // Prevent the update
    };
    
  };
  const ProcessRowUpdateError = (error) => {
    console.log(error);
  };
  const validateRow = (row) => {
    console.log(row.Name.trim())
    return row.Name.trim() !== ''; // Ensure col1 is filled
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  
  const columns = [
    {
        field: 'EventID',
        headerName: '',
        width: 120,
        renderCell: (params) => {
          return <a href={'../admin/addeventquestion/' + params.row.EventID}>Add Question</a>;
        },
      },
    { field: 'CourseName', headerName: 'Course', width: 150, editable: true ,type: 'singleSelect',valueOptions: courses.map(function(a) {return a.CourseName;}),renderEditCell: (params) => <CourseColumnEditComponent {...params} />},
    { field: 'SubjectName', headerName: 'Subject', width: 150, editable: true , type: 'singleSelect',
        valueOptions: ({ row }) => {
            if (!row) {
              return subjects.map(function(a) {return a.SubjectName;});
            }
            if(row.CourseName !==''){
                let newsubjects = subjects.filter(function (el) {return el.CourseName==row.CourseName});
                return newsubjects.map(function(a) {return a.SubjectName;});
            }
            else{
                return subjects.map(function(a) {return a.SubjectName;});
            }
          },
    },
    { field: 'EventName', headerName: 'Event Name', width: 200, editable: true },
    { field: 'EventDate',headerName: 'Event Date',type: 'date',width: 120,editable: true,
        valueFormatter: (params) => {if (!params) return;const date = new Date(params);return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;},
    },
    { field: 'Duration', headerName: 'Duration',type:'number', width: 100, editable: true },
    { field: 'SecretCode', headerName: 'Secret Code',type:'number', width: 100, editable: true },
    { field: 'TotalMarks', headerName: 'Total Marks',type:'number', width: 100, editable: true },
    { field: 'TotalQuestion', headerName: 'Total Question',type:'number', width: 120, editable: true },
    { field: 'VivaTotalQuestions', headerName: 'Viva Total Questions',type:'number', width: 150, editable: true },
    { field: 'VivaDuration', headerName: 'Viva Duration',type:'number', width: 110, editable: true },
    { field: 'Deleted',headerName: 'Deleted',width: 80,align: 'left',headerAlign: 'left',type: 'boolean',editable: true},
    { field: 'IsActive',headerName: 'IsActive',width: 80,align: 'left',headerAlign: 'left',type: 'boolean',editable: true},
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.EventID}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={ProcessRowUpdateError}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        rowCount={TotalCount || 0}
        pageSizeOptions={[1,5,10]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        // getCellClassName={(params) => 
        //   params.error? 'errorCell' : ''
        // }
      />
    </Box>
  );
}
