import axiosInstance from '../axiosInstance';

class UploadFilesService {
  UploadQuestionImageType(file,QuestonId,ImageId,Type) {
    let formData = new FormData();
    formData.append("ImageFile", file);
    formData.append("Id", QuestonId);
    formData.append("ImageId", ImageId);
    formData.append("Type", Type);
    return axiosInstance.post("Question/UploadQuestionImageType", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }
  UploadSubjectPointStepImageType(file,SubjectStepId,ImageId,TypeId) {
    let formData = new FormData();
    formData.append("ImageFile", file);
    formData.append("Id", SubjectStepId);
    formData.append("ImageId", ImageId);
    formData.append("TypeId", TypeId);
    return axiosInstance.post("SubjectPointStep/UploadSubjectPointStepImageType", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }
  UploadSubjectPointImageType(file,SubjectPointId,ImageId,TypeId) {
    let formData = new FormData();
    formData.append("ImageFile", file);
    formData.append("Id", SubjectPointId);
    formData.append("ImageId", ImageId);
    formData.append("TypeId", TypeId);
    return axiosInstance.post("SubjectPoint/UploadSubjectPointImageType", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }
    UploadSubjectImageType(file,SubjectId,ImageId,TypeId) {
      let formData = new FormData();
      formData.append("ImageFile", file);
      formData.append("Id", SubjectId);
      formData.append("ImageId", ImageId);
      formData.append("TypeId", TypeId);
      return axiosInstance.post("Subject/UploadSubjectImageType", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
    }
    UploadCourseImageType(file,CourseId,ImageId,TypeId) {
      let formData = new FormData();
      formData.append("ImageFile", file);
      formData.append("Id", CourseId);
      formData.append("ImageId", ImageId);
      formData.append("TypeId", TypeId);
      return axiosInstance.post("Course/UploadCourseImageType", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
    }
    UploadSlideImageType(file,SlideId,ImageId,TypeId) {
      let formData = new FormData();
      formData.append("ImageFile", file);
      formData.append("Id", SlideId);
      formData.append("ImageId", ImageId);
      formData.append("TypeId", TypeId);
      return axiosInstance.post("Slide/UploadSlideImageType", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
    }
   UploadSlideImage(file,SlideId,ImageId) {
        let formData = new FormData();
        formData.append("ImageFile", file);
        formData.append("Id", SlideId);
        formData.append("ImageId", ImageId);
        return axiosInstance.post("Slide/UploadSlideImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        });
    }
    UploadCategoryImage(file,CategoryImageMapId,CategoryID,ImageId) {
    let formData = new FormData();
    formData.append("ImageFile", file);
    formData.append("CategoryImageMapId", CategoryImageMapId);
    formData.append("CategoryID", CategoryID);
    formData.append("ImageId", ImageId);
    return axiosInstance.post("Category/UploadCategoryImage", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
    }
    UploadCategoryImageSlide(file,CategoryImgSlideMapId,CategoryID,ImageId) {
      let formData = new FormData();
      formData.append("ImageFile", file);
      formData.append("CategoryImgSlideMapId", CategoryImgSlideMapId);
      formData.append("CategoryID", CategoryID);
      formData.append("ImageId", ImageId);
      return axiosInstance.post("Category/UploadCategoryImageSlide", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
      }
      
}

export default new UploadFilesService();