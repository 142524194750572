import { Fragment } from "react";
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Alert, Button, CardActionArea, CardActions } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import DashboardCard from "./component/dashboardcard";

function Index(){
  
  //const history = useHistory();
  let navigate =useNavigate();
  
    const Gotolink=(url)=>{
      navigate(url);
    }
  const [token, setToken] = useState(localStorage.getItem("token"));
   const [userCount,setuserCount]=useState(0);
   const [companyCount,setcompanyCount]=useState(0);
   const [contactCount,setcontactCount]=useState(0);
   const [name,setname]=useState('');
   const [email,setemail]=useState('');
   const [status,setstatus]=useState(false);
   const [role,setrole]=useState('User');
   const [cardlist,setCardList]=useState('');
   useEffect(()=>{
    console.log('token');
    console.log(token);
    if (token === 'null') {
      //console.log('accessToken');
      //console.log(token);
      navigate("../login");
      //return history.push("/login");
    }
    else{
      axiosInstance.get('Common/GetDashboardData').then((response)=>{
        console.log(response.data);
        console.log(response.data.Data);
        if(response.data.Data.length !== 0){
          setCardList(response.data.Data);
          setname(response.data.Data[0].Name)
        }
       
       });
    }
   },[])
    return (
        <Fragment>
          <Box sx={{ flexGrow: 1 }}>
          <Alert icon={false} severity="info">
              Welcome to Sips Education : {name} 
          </Alert>
          
          <br></br>
          <br></br>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {cardlist.length>0?
                (
                  cardlist.map((item,index)=>(
                    <DashboardCard text={item.TextValue} value={item.CountValue}  ></DashboardCard>
                  ))
                ):
                (
                  <Typography>No data to display.</Typography>
                )
            }
          </Grid>
          
          {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
               <Grid item xs={2} sm={4} md={4}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Company
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {companyCount}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button size="small" color="primary" onClick={()=> Gotolink('../company')} >
                        Go to list
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Users
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {userCount}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button size="small" color="primary" onClick={()=>Gotolink('../userlist')} >
                        Go to list
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Contacts
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {contactCount}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button size="small" color="primary">
                        Go to list
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
            </Grid> */}
          
        </Box>
         
         
        </Fragment>
    )
}
export default Index;