import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Field, Form, Formik, useFormik } from 'formik';
import { Container, Box, Typography, TextField, Button, CssBaseline, FormControl, InputLabel, Select, MenuItem, Grid, FormControlLabel, Checkbox, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { ArrowBack, Block, CheckBox } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import axiosInstance from '../../axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import uploadfile_service from '../../services/uploadfile_service';
export default function AddCourse(){
  const defaultTheme = createTheme();
  let { id} = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const [tabDisabled,settabDisabled] =useState(true);
  const [IsActive, setIsActive] = useState(false);
  const [Status, setStatus] = useState(false);
  const [Deleted, setDeleted] = useState(false);

//   const [selectedCourseBaseImage, setselectedCourseBaseImage] = useState(null);
//   const [selectedCourseImage, setselectedCourseImage] = useState(null);
  const [selectedCourseBaseImageSM, setselectedCourseBaseImageSM] = useState(null);
  const [selectedCourseImageSM, setselectedCourseImageSM] = useState(null);
  const [selectedCourseBaseImageLG, setselectedCourseBaseImageLG] = useState(null);
  const [selectedCourseImageLG, setselectedCourseImageLG] = useState(null);
  //const [imageid,setimageid]=useState(0);
  const [ImageIDSM,setImageIDSM]=useState(0);
  const [ImageIDLG,setImageIDLG]=useState(0);

//  async function displayImageByName(
//     filename
//   ) {
//     // Fetch the image.
//     var date = new Date();
//     axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
//       console.log(response);
//       //const imageElement = document.getElementById(imageId);
//       //imageElement.src = response.data;
//       //setselectedCourseBaseImage(response.data);
//      });
//   };
  async function displayImageByNameLG(
    filename
  ) {
    var date = new Date();
    axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
      setselectedCourseBaseImageLG(response.data);
     });
  };
  async function displayImageByNameSM(
    filename
  ) {
    var date = new Date();
    axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
      setselectedCourseBaseImageSM(response.data);
     });
  };
  useEffect((event)=>{
    console.log("id");
    console.log(id);
    if(id != undefined || id != null){
        settabDisabled(false);
        axiosInstance.get('Course/GetCourseMaster/' + id).then((response)=>{
            //console.log(response.data);
            //console.log(response.data.Data[0]);
            var ndata=response.data.Data[0];
            console.log('ndata');
            console.log(ndata);
            addform.values.CourseName=ndata.CourseName;
            addform.values.CourseDescription=ndata.CourseDescription;
            addform.values.CourseFee=ndata.CourseFee;
            addform.values.Status=ndata.Status;
            addform.values.Deleted=ndata.Deleted;
            addform.values.CourseImageSm=ndata.CourseImageSM;
            addform.values.CourseImageLg=ndata.CourseImageLG;
            setStatus(ndata.Status);
            setDeleted(ndata.Deleted);
            //setimageid(ndata.ImageID);
            setImageIDLG(ndata.CourseImageLG);
            setImageIDSM(ndata.CourseImageSM);
            if(ndata.ImageNameLG.trim() !== ""){
                displayImageByNameLG(ndata.ImageNameLG);
            }
            if(ndata.ImageNameSM.trim() !== ""){
                displayImageByNameSM(ndata.ImageNameSM);
            }
           });
    }
  },[]);

  const addform = useFormik({
    initialValues: {
        CourseName: '',
        CourseDescription:'',
        CourseFee:'',
        CourseImageSm:0,
        CourseImageLg:0,
        Status:true,
        Deleted:false
    },
    validationSchema: Yup.object({
        CourseName: Yup.string().required('Name is required'),
        CourseFee: Yup.string().required('Fee is required')
    }),
    onSubmit: (values) => {
        console.log(values)
        if(id===undefined || id ===null){
            axiosInstance.post('Course/AddCourseMaster',{
                "CourseId": 0,
                "CourseName": values.CourseName,
                "CourseDescription": values.CourseDescription,
                "CourseFee":values.CourseFee,
                "CourseImageSm":values.CourseImageSm,
                "CourseImageLg":values.CourseImageLg,
                "Status":values.Status,
                "Deleted": values.Deleted
            },
            ).then((response)=>{
                //getVideoList();
                console.log(response.data);
                alert("Added Course successfully")
                
            });
        }
        else{
            axiosInstance.post('Course/UpdateCourseMaster',{
                "CourseId": parseInt(id),
                "CourseName": values.CourseName,
                "CourseDescription": values.CourseDescription,
                "CourseFee":values.CourseFee,
                "CourseImageSm":values.CourseImageSm,
                "CourseImageLg":values.CourseImageLg,
                "Status":values.Status,
                "Deleted": values.Deleted
            },
            ).then((response)=>{
                //getVideoList();
                console.log(response.data);
                alert("Updated Course successfully")
                
            });
        }
       
    }
  });
  const routeChange = () =>{ 
    navigate('/admin/Courselist');
  }
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }; 
  const handleIsActiveChange = (event) => {
    //console.log(event.target.value);
    setIsActive(IsActive?false:true)
    addform.values.IsActive=IsActive?false:true;
    //console.log(IsActive)
    //console.log(IsActive)
    //setIsActive(event.target.value)
    //setBusinessTypeId(event.target.value);
  };
  const handleStatusChange = (event) => {
    setStatus(Status?false:true)
    addform.values.Status=Status?false:true;
  };
  const handleDeletedChange = (event) => {
    setDeleted(Deleted?false:true)
    addform.values.Deleted=Deleted?false:true;
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  };
  const UploadCourseImage = event => {
    // console.log('id:' + id);
    // console.log('imageid:' + imageid);
    // uploadfile_service.UploadCourseImage(selectedCourseImage,id,imageid).then((data)=>{
    //   //alert(data.data.Message)
    //   alert("Record saved successfull");
    //   console.log(data);
    // }).catch((error)=>{
    //   alert("Failed");
    //   console.log(error);
    // })
  };
  const UploadCourseImageLG = event => {
    console.log('id:' + id);
    console.log('imageidlg:' + ImageIDLG);
    uploadfile_service.UploadCourseImageType(selectedCourseImageLG,id,ImageIDLG,2).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  const UploadCourseImageSM = event => {
    console.log('id:' + id);
    console.log('imageidsm:' + ImageIDSM);
    uploadfile_service.UploadCourseImageType(selectedCourseImageSM,id,ImageIDSM,1).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  
    return(
        <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          <CssBaseline />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               <Grid item xs={6}>
                  <Button 
                    style={{width:"150px"}}
                    startIcon={<ArrowBack />} onClick={routeChange}>
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="h6" variant="h6">
                      Add Course
                    </Typography>
                </Grid>
          </Grid>
          
          
            <div style={{textAlign:'left'}}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    <Tab label="Course detail" value="1" />
                    <Tab label="Image" value="2" disabled={tabDisabled}/>
                  </TabList>
                </Box>
                <TabPanel value="1">
                <Box
                    component="form"
                    // sx={{
                    //   '& .MuiTextField-root': { m: 1, width: '35ch' },
                    // }}
                    onSubmit={addform.handleSubmit}
                  >
                  <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                      <Grid item xs={6} sm={6} md={6}>
                      <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                name="CourseName"
                                id="CourseName"
                                placeholder="Course Name"
                                value={addform.values.CourseName}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                                error={addform.errors.CourseName && addform.touched.CourseName ?true:false}
                                helperText={addform.errors.CourseName && addform.touched.CourseName ?addform.errors.CourseName:null}
                              />
                              
                      </Grid>
                      <Grid  item xs={6} sm={6} md={6}>
                            <TextField
                                        fullWidth
                                        type="text"
                                        autoComplete="off"
                                        name="CourseDescription"
                                        id="CourseDescription"
                                        placeholder="Course Description"
                                        value={addform.values.CourseDescription}
                                        onChange={addform.handleChange}
                                        onBlur={addform.handleBlur}
                                    />
                      </Grid>
                      <Grid  item xs={6} sm={6} md={6}>
                            <TextField
                                        fullWidth
                                        type="number"
                                        autoComplete="off"
                                        name="CourseFee"
                                        id="CourseFee"
                                        placeholder="Course Fee"
                                        value={addform.values.CourseFee}
                                        onChange={addform.handleChange}
                                        onBlur={addform.handleBlur}
                                        error={addform.errors.CourseFee && addform.touched.CourseFee ?true:false}
                                        helperText={addform.errors.CourseFee && addform.touched.CourseFee ?addform.errors.CourseFee:null}
                                    />
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} style={{textAlign:'left',paddingLeft:'15px'}}>
                          
                          <FormControlLabel control={
                            <Checkbox
                            value={addform.values.Status}
                            checked={addform.values.Status}
                            onChange={handleStatusChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          } name='Status'
                          label="Status"
                          labelPlacement='end'>,

                          </FormControlLabel>
                          
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} style={{textAlign:'left',paddingLeft:'15px'}}>
                          
                          <FormControlLabel control={
                            <Checkbox
                            value={addform.values.Deleted}
                            checked={addform.values.Deleted}
                            onChange={handleDeletedChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          } name='Deleted'
                          label="Deleted"
                          labelPlacement='end'>,

                          </FormControlLabel>
                          
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                  </Grid>
                  <br></br>
                  <Button
                    type="submit"
                    variant="contained"
                    //sx={{ mt: 1, mb: 1 ,m:1}}
                    style={{width:"150px"}}
                  >
                    Save 
                  </Button>
                  </Box>
                  
                </TabPanel>
                
                <TabPanel value="2">
                           <p>Large Image</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedCourseBaseImageLG && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedCourseBaseImageLG}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedCourseBaseImageLG(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="myCourseImageLG"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedCourseBaseImageLG(result);
                                        });
                                        setselectedCourseImageLG(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadCourseImageLG}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>
                            <p>Small Image</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedCourseBaseImageSM && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedCourseBaseImageSM}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedCourseBaseImageSM(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="myCourseImageSM"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedCourseBaseImageSM(result);
                                        });
                                        setselectedCourseImageSM(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadCourseImageSM}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>
                </TabPanel>
              </TabContext>
            </Box>       
                       
            </div>
           <div>
           </div>
        </Container>
      </ThemeProvider>
    );
}