import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Field, Form, Formik, useFormik } from 'formik';
import { Container, Box, Typography, TextField, Button, CssBaseline, FormControl, InputLabel, Select, MenuItem, Grid, FormControlLabel, Checkbox, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { ArrowBack, Block, CheckBox } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import axiosInstance from '../../axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import uploadfile_service from '../../services/uploadfile_service';
export default function AddQuestion(){
  const defaultTheme = createTheme();
  let { id} = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const [tabDisabled,settabDisabled] =useState(true);
  const [IsActive, setIsActive] = useState(false);
  const [Status, setStatus] = useState(false);
  const [Deleted, setDeleted] = useState(false);
  const [selectedQuestionBaseImage, setselectedQuestionBaseImage] = useState(null);
  const [selectedQuestionImage, setselectedQuestionImage] = useState(null);

  const [selectedOptionABaseImage, setselectedOptionABaseImage] = useState(null);
  const [selectedOptionAImage, setselectedOptionAImage] = useState(null);

  const [selectedOptionBBaseImage, setselectedOptionBBaseImage] = useState(null);
  const [selectedOptionBImage, setselectedOptionBImage] = useState(null);

  const [selectedOptionCBaseImage, setselectedOptionCBaseImage] = useState(null);
  const [selectedOptionCImage, setselectedOptionCImage] = useState(null);

  const [selectedOptionDBaseImage, setselectedOptionDBaseImage] = useState(null);
  const [selectedOptionDImage, setselectedOptionDImage] = useState(null);

  const [selectedOptionEBaseImage, setselectedOptionEBaseImage] = useState(null);
  const [selectedOptionEImage, setselectedOptionEImage] = useState(null);


  const [QuestionPhoto,setQuestionPhoto]=useState(0);
  const [OptionA_Photo,setOptionA_Photo]=useState(0);
  const [OptionB_Photo,setOptionB_Photo]=useState(0);
  const [OptionC_Photo,setOptionC_Photo]=useState(0);
  const [OptionD_Photo,setOptionD_Photo]=useState(0);
  const [OptionE_Photo,setOptionE_Photo]=useState(0);

  const [CourseList,setCourseList]=useState([]);
  const [CourseID, setCourseID] = useState(0);
  const [SubjectList,setSubjectList]=useState([]);
  const [SubjectID, setSubjectID] = useState(0);
  const [QuestionTypeList,setQuestionTypeList]=useState([]);
  const [QuestionTypeID, setQuestionTypeID] = useState(0);
  async function displayImageByNameType(
    filename,type
  ) {
    var date = new Date();
    axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
      if(type=="QuestionPhoto")  {
        setselectedQuestionBaseImage(response.data);
      }else if(type=="OptionA_Photo"){
        setselectedOptionABaseImage(response.data)
      }else if(type=="OptionB_Photo"){
        setselectedOptionBBaseImage(response.data)
      }else if(type=="OptionC_Photo"){
        setselectedOptionCBaseImage(response.data)
      }else if(type=="OptionD_Photo"){
        setselectedOptionDBaseImage(response.data)
      }else if(type=="OptionE_Photo"){
        setselectedOptionEBaseImage(response.data)
      }
     });
  };
  
  useEffect((event)=>{
    axiosInstance.get('Course/GetAllCourseList').then((response)=>{
        console.log(response.data);
        setCourseList(response.data);
       });
    axiosInstance.get('Common/GetQuestionTypeList').then((response)=>{
        console.log(response.data);
        setQuestionTypeList(response.data);
       });
    console.log("id");
    console.log(id);
    if(id != undefined || id != null){
        settabDisabled(false);
        axiosInstance.get('Question/GetQuestionMaster/' + id).then((response)=>{
            console.log(response.data);
            //console.log(response.data.Data[0]);
            var ndata=response.data.Data[0];
            console.log('ndata');
            console.log(ndata);
            addform.values.Question=ndata.Question;
            addform.values.CourseId=ndata.CourseID;
            addform.values.SubjectId=ndata.SubjectID;
            addform.values.QuestionTypeId=ndata.QuestionTypeID;
            addform.values.OptionA=ndata.OptionA;
            addform.values.OptionB=ndata.OptionB;
            addform.values.OptionC=ndata.OptionC;
            addform.values.OptionD=ndata.OptionD;
            addform.values.OptionE=ndata.OptionE;
            addform.values.CorrectAnswer=ndata.CorrectAnswer;
            addform.values.Marks=ndata.Marks;
            addform.values.Status=ndata.Status;
            addform.values.QuestionPhoto=ndata.QuestionPhoto;
            addform.values.OptionAPhoto=ndata.OptionA_Photo;
            addform.values.OptionBPhoto=ndata.OptionB_Photo;
            addform.values.OptionCPhoto=ndata.OptionC_Photo;
            addform.values.OptionDPhoto=ndata.OptionD_Photo;
            addform.values.OptionEPhoto=ndata.OptionE_Photo;
            setStatus(ndata.Status);
            setCourseID(ndata.CourseID);
            getSubjectList(ndata.CourseID);
            setSubjectID(ndata.SubjectID);
            setQuestionTypeID(ndata.QuestionTypeID);
            
            setQuestionPhoto(ndata.QuestionPhoto);
            setOptionA_Photo(ndata.OptionA_Photo);
            setOptionB_Photo(ndata.OptionB_Photo);
            setOptionC_Photo(ndata.OptionC_Photo);
            setOptionD_Photo(ndata.OptionD_Photo);
            setOptionE_Photo(ndata.OptionE_Photo);
            //setImageIDLG(ndata.ImageIDLG);
            //setImageIDSM(ndata.ImageIDSM);
            if(ndata.ImageNameQuestion.trim() !== ""){
              console.log("calling ImageNameQuestion")
                displayImageByNameType(ndata.ImageNameQuestion,'QuestionPhoto');
            }
            if(ndata.ImageNameOptionA.trim() !== ""){
              console.log("calling ImageNameOptionA")
                displayImageByNameType(ndata.ImageNameOptionA,'OptionA_Photo');
            }
            //if(ndata.ImageNameOptionB!=null || ndata.ImageNameOptionB!==""){
              if(ndata.ImageNameOptionB.trim() !== ""){
              console.log("calling ImageNameOptionB")
                displayImageByNameType(ndata.ImageNameOptionB,'OptionB_Photo');
            }
            if(ndata.ImageNameOptionC.trim() !== ""){
              console.log("calling ImageNameOptionC")
                displayImageByNameType(ndata.ImageNameOptionC,'OptionC_Photo');
            }
            if(ndata.ImageNameOptionD.trim() !== ""){
              console.log("calling ImageNameOptionD")
                displayImageByNameType(ndata.ImageNameOptionD,'OptionD_Photo');
            }
            if(ndata.ImageNameOptionE.trim() !== ""){
              console.log("calling ImageNameOptionE")
                displayImageByNameType(ndata.ImageNameOptionE,'OptionE_Photo');
            }
            // if(ndata.ImageNameLG!=null){
            //     displayImageByNameLG(ndata.ImageNameLG);
            // }
            // if(ndata.ImageNameSM!=null){
            //     displayImageByNameSM(ndata.ImageNameSM);
            // }
           });
    }
  },[]);

  const addform = useFormik({
    initialValues: {
        CourseId: '',
        SubjectId: '',
        Question: '',
        Language: 'english',
        QuestionPhoto: 0,
        OptionA: '',
        OptionAPhoto: 0,
        OptionB: '',
        OptionBPhoto: 0,
        OptionC: '',
        OptionCPhoto: 0,
        OptionD: '',
        OptionDPhoto: 0,
        OptionE: '',
        OptionEPhoto: 0,
        CorrectAnswer: '',
        Marks: 0,
        QuestionTypeId: '',
        Status: true,
        Deleted: false,
    },
    validationSchema: Yup.object({
        Question: Yup.string().required('Question is required'),
        OptionA: Yup.string().required('Option A is required'),
        OptionB: Yup.string().required('Option B is required'),
        OptionC: Yup.string().required('Option C is required'),
        OptionD: Yup.string().required('Option D is required'),
        OptionE: Yup.string().required('Option E is required'),
        CorrectAnswer: Yup.string().required('Correct Answer is required'),
        Marks: Yup.string().required('Marks is required'),
        CourseId:Yup.string().required('Course is required'),
        SubjectId:Yup.string().required('Subject is required'),
        QuestionTypeId:Yup.string().required('Question Type is required'),
    }),
    onSubmit: (values) => {
        //console.log(addform.errors);
        console.log(values)
        if(id===undefined || id ===null){
            axiosInstance.post('Question/AddQuestionMaster',{
                "QuestionId": 0,
                "Question": values.Question,
                "CourseId": values.CourseId,
                "SubjectId": values.SubjectId,
                "Language": values.Language,
                "QuestionPhoto": values.QuestionPhoto,
                "OptionA": values.OptionA,
                "OptionAPhoto": values.OptionAPhoto,
                "OptionB": values.OptionB,
                "OptionBPhoto": values.OptionBPhoto,
                "OptionC": values.OptionC,
                "OptionCPhoto": values.OptionCPhoto,
                "OptionD": values.OptionD,
                "OptionDPhoto": values.OptionDPhoto,
                "OptionE": values.OptionE,
                "OptionEPhoto": values.OptionEPhoto,
                "CorrectAnswer": values.CorrectAnswer,
                "Marks": values.Marks,
                "QuestionTypeId": values.QuestionTypeId,
                "Status": values.Status,
                "Deleted": false
            },
            ).then((response)=>{
                //getVideoList();
                console.log(response.data);
                alert("Added Question successfully")
            });
        }
        else{
            axiosInstance.post('Question/UpdateQuestionMaster',{
                "QuestionId": parseInt(id),
                "Question": values.Question,
                "CourseId": values.CourseId,
                "SubjectId": values.SubjectId,
                "Language": values.Language,
                "QuestionPhoto": values.QuestionPhoto,
                "OptionA": values.OptionA,
                "OptionAPhoto": values.OptionAPhoto,
                "OptionB": values.OptionB,
                "OptionBPhoto": values.OptionBPhoto,
                "OptionC": values.OptionC,
                "OptionCPhoto": values.OptionCPhoto,
                "OptionD": values.OptionD,
                "OptionDPhoto": values.OptionDPhoto,
                "OptionE": values.OptionE,
                "OptionEPhoto": values.OptionEPhoto,
                "CorrectAnswer": values.CorrectAnswer,
                "Marks": values.Marks,
                "QuestionTypeId": values.QuestionTypeId,
                "Status": values.Status,
                "Deleted": false
            },
            ).then((response)=>{
                //getVideoList();
                console.log(response.data);
                alert("Updated Question successfully")
            });
        }
       
    }
  });
  const routeChange = () =>{ 
    navigate('/admin/Questionlist');
  }
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }; 
  
  const handleIsActiveChange = (event) => {
    setIsActive(IsActive?false:true)
    addform.values.IsActive=IsActive?false:true;
  };
  const handleStatusChange = (event) => {
    setStatus(Status?false:true)
    addform.values.Status=Status?false:true;
  };
  const handleCourseIDChange = (event) => {
    console.log(event.target.value);
    setCourseID(event.target.value);
    getSubjectList(event.target.value);
    addform.values.CourseId=event.target.value;
  };
  const handleSubjectIDChange = (event) => {
    console.log(event.target.value);
    setSubjectID(event.target.value);
    addform.values.SubjectId=event.target.value;
  };
  const handleQuestionTypeIDChange = (event) => {
    console.log(event.target.value);
    setQuestionTypeID(event.target.value);
    addform.values.QuestionTypeId=event.target.value;
  };
  function getSubjectList(courseid){
    axiosInstance.get('Subject/GetSubjectListByCourseID/?courseid=' + courseid).then((response)=>{
        console.log(response.data);
        setSubjectList(response.data);
       });
  }
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  };
  
  const UploadImagetype = (selectedimage,imageid,type)=> event => {
    console.log('id:' + id);
    //console.log('imageidsm:' + ImageIDSM);
    uploadfile_service.UploadQuestionImageType(selectedimage,id,imageid,type).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  const UploadQuestionImage = event => {
    console.log('id:' + id);
    //console.log('imageidsm:' + ImageIDSM);
    uploadfile_service.UploadQuestionImageType(selectedQuestionImage,id,QuestionPhoto,1).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  const UploadOptionAImage = event => {
    console.log('id:' + id);
    //console.log('imageidlg:' + ImageIDLG);
    uploadfile_service.UploadQuestionImageType(selectedOptionAImage,id,OptionA_Photo,2).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  
    return(
        <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          <CssBaseline />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               <Grid item xs={6}>
                  <Button 
                    style={{width:"150px"}}
                    startIcon={<ArrowBack />} onClick={routeChange}>
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="h6" variant="h6">
                      Add Question
                    </Typography>
                </Grid>
          </Grid>
          
          
            <div style={{textAlign:'left'}}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    <Tab label="Question detail" value="1" />
                    <Tab label="Image" value="2" disabled={tabDisabled}/>
                  </TabList>
                </Box>
                <TabPanel value="1">
                <Box
                    component="form"
                    // sx={{
                    //   '& .MuiTextField-root': { m: 1, width: '35ch' },
                    // }}
                    onSubmit={addform.handleSubmit}
                  >
                  <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={4}>
                                <FormControl fullWidth>
                                        <InputLabel id="lCourseId">Course</InputLabel>
                                        <Select
                                        labelId="lCourseId"
                                        id="CourseId"
                                        value={CourseID}
                                        label="Course"
                                        onChange={handleCourseIDChange}
                                        onBlur={addform.handleBlur}
                                        error={addform.errors.CourseId && addform.touched.CourseId ?true:false}
                                        //helperText={addform.errors.CourseId && addform.touched.CourseId ?addform.errors.CourseId:null}
                                         >
                                        {CourseList.map((course,index) => (
                                            <MenuItem
                                            value={course.CourseId}
                                            key={`course_courseid${index}`}
                                            >
                                            <em>{course.CourseName}</em>
                                            </MenuItem>
                                        ))}
                                        
                                        </Select>
                                        {/* {addform.touched.CourseId && addform.errors.CourseId && <span style={{color:"red",fontSize:"12px",paddingLeft:"12px"}}>{addform.errors.CourseId}</span>} */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} sm={4} md={4}>
                                <FormControl fullWidth>
                                        <InputLabel id="lSubjectId">Subject</InputLabel>
                                        <Select
                                        labelId="lSubjectId"
                                        id="SubjectId"
                                        value={SubjectID}
                                        label="Subject"
                                        onChange={handleSubjectIDChange}
                                        onBlur={addform.handleBlur}
                                        error={addform.errors.SubjectId && addform.touched.SubjectId ?true:false}
                                        //helperText={addform.errors.SubjectId && addform.touched.SubjectId ?addform.errors.SubjectId:null}
                                        >
                                        {SubjectList.map((subject,index) => (
                                            <MenuItem
                                            value={subject.SubjectId}
                                            key={`Subject_Subjectid${index}`}
                                            >
                                            <em>{subject.SubjectName}</em>
                                            </MenuItem>
                                        ))}
                                        
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} sm={4} md={4}>
                                <FormControl fullWidth>
                                        <InputLabel id="lQuestionTypeId">QuestionType</InputLabel>
                                        <Select
                                        labelId="lQuestionTypeId"
                                        id="QuestionTypeId"
                                        value={QuestionTypeID}
                                        label="QuestionType"
                                        onChange={handleQuestionTypeIDChange}
                                        onBlur={addform.handleBlur}
                                        error={addform.errors.QuestionTypeId && addform.touched.QuestionTypeId ?true:false}
                                        //helperText={addform.errors.QuestionTypeId && addform.touched.QuestionTypeId ?addform.errors.QuestionTypeId:null}
                                        >
                                        {QuestionTypeList.map((QuestionType,index) => (
                                            <MenuItem
                                            value={QuestionType.QuestionTypeId}
                                            key={`QuestionType_QuestionTypeid${index}`}
                                            >
                                            <em>{QuestionType.QuestionTypeName}</em>
                                            </MenuItem>
                                        ))}
                                        
                                        </Select>
                                    </FormControl>
                                </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                      <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                name="Question"
                                id="Question"
                                placeholder="Question"
                                value={addform.values.Question}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                                error={addform.errors.Question && addform.touched.Question ?true:false}
                                helperText={addform.errors.Question && addform.touched.Question ?addform.errors.Question:null}
                              />
                              
                      </Grid>

                      <Grid item xs={4} sm={4} md={4}>
                        <TextField
                                    fullWidth
                                    type="text"
                                    autoComplete="off"
                                    name="OptionA"
                                    id="OptionA"
                                    placeholder="Option A"
                                    value={addform.values.OptionA}
                                    onChange={addform.handleChange}
                                    onBlur={addform.handleBlur}
                                    error={addform.errors.OptionA && addform.touched.OptionA ?true:false}
                                    helperText={addform.errors.OptionA && addform.touched.OptionA ?addform.errors.OptionA:null}
                                />
                      </Grid>
                      <Grid item xs={4} sm={4} md={4}>
                        <TextField
                                    fullWidth
                                    type="text"
                                    autoComplete="off"
                                    name="OptionB"
                                    id="OptionB"
                                    placeholder="Option B"
                                    value={addform.values.OptionB}
                                    onChange={addform.handleChange}
                                    onBlur={addform.handleBlur}
                                    error={addform.errors.OptionB && addform.touched.OptionB ?true:false}
                                    helperText={addform.errors.OptionB && addform.touched.OptionB ?addform.errors.OptionB:null}
                                />
                      </Grid>
                      <Grid item xs={4} sm={4} md={4}>
                        <TextField
                                    fullWidth
                                    type="text"
                                    autoComplete="off"
                                    name="OptionC"
                                    id="OptionC"
                                    placeholder="Option C"
                                    value={addform.values.OptionC}
                                    onChange={addform.handleChange}
                                    onBlur={addform.handleBlur}
                                    error={addform.errors.OptionC && addform.touched.OptionC ?true:false}
                                    helperText={addform.errors.OptionC && addform.touched.OptionC ?addform.errors.OptionC:null}
                                />
                      </Grid>
                      <Grid item xs={4} sm={4} md={4}>
                        <TextField
                                    fullWidth
                                    type="text"
                                    autoComplete="off"
                                    name="OptionD"
                                    id="OptionD"
                                    placeholder="Option D"
                                    value={addform.values.OptionD}
                                    onChange={addform.handleChange}
                                    onBlur={addform.handleBlur}
                                    error={addform.errors.OptionD && addform.touched.OptionD ?true:false}
                                    helperText={addform.errors.OptionD && addform.touched.OptionD ?addform.errors.OptionD:null}
                                />
                      </Grid>
                      <Grid item xs={4} sm={4} md={4}>
                        <TextField
                                    fullWidth
                                    type="text"
                                    autoComplete="off"
                                    name="OptionE"
                                    id="OptionE"
                                    placeholder="Option E"
                                    value={addform.values.OptionE}
                                    onChange={addform.handleChange}
                                    onBlur={addform.handleBlur}
                                    error={addform.errors.OptionE && addform.touched.OptionE ?true:false}
                                    helperText={addform.errors.OptionE && addform.touched.OptionE ?addform.errors.OptionE:null}
                                />
                      </Grid>
                      <Grid item xs={4} sm={4} md={4}>
                        <TextField
                                    fullWidth
                                    type="text"
                                    autoComplete="off"
                                    name="CorrectAnswer"
                                    id="CorrectAnswer"
                                    placeholder="Correct Answer"
                                    value={addform.values.CorrectAnswer}
                                    onChange={addform.handleChange}
                                    onBlur={addform.handleBlur}
                                    error={addform.errors.CorrectAnswer && addform.touched.CorrectAnswer ?true:false}
                                    helperText={addform.errors.CorrectAnswer && addform.touched.CorrectAnswer ?addform.errors.CorrectAnswer:null}
                                />
                      </Grid>
                      <Grid item xs={4} sm={4} md={4}>
                        <TextField
                                    fullWidth
                                    type="number"
                                    autoComplete="off"
                                    name="Marks"
                                    id="Marks"
                                    placeholder="Marks"
                                    value={addform.values.Marks}
                                    onChange={addform.handleChange}
                                    onBlur={addform.handleBlur}
                                    error={addform.errors.Marks && addform.touched.Marks ?true:false}
                                    helperText={addform.errors.Marks && addform.touched.Marks ?addform.errors.Marks:null}
                                />
                      </Grid>
                      
                      
                      <Grid item xs={2} sm={4} md={4} style={{textAlign:'left',paddingLeft:'15px'}}>
                          
                          <FormControlLabel control={
                            <Checkbox
                            value={addform.values.Status}
                            checked={addform.values.Status}
                            onChange={handleStatusChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          } name='Status'
                          label="Status"
                          labelPlacement='end'>,

                          </FormControlLabel>
                          
                      </Grid>
                      
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                  </Grid>
                  <br></br>
                  <Button
                    type="submit"
                    variant="contained"
                    //sx={{ mt: 1, mb: 1 ,m:1}}
                    style={{width:"150px"}}
                  >
                    Save 
                  </Button>
                  </Box>
                  
                </TabPanel>
                
                <TabPanel value="2">
                            <p>Question Photo</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedQuestionBaseImage && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedQuestionBaseImage}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedQuestionBaseImage(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="myQuestionImage"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedQuestionBaseImage(result);
                                        });
                                        setselectedQuestionImage(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadImagetype(selectedQuestionImage,QuestionPhoto,'QuestionPhoto')}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>
                           <p>Option A Photo</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedOptionABaseImage && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedOptionABaseImage}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedOptionABaseImage(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="myOptionAImage"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedOptionABaseImage(result);
                                        });
                                        setselectedOptionAImage(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadImagetype(selectedOptionAImage,OptionA_Photo,'OptionA_Photo')}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>

                            <p>Option B Photo</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedOptionBBaseImage && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedOptionBBaseImage}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedOptionBBaseImage(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="myOptionBImage"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedOptionBBaseImage(result);
                                        });
                                        setselectedOptionBImage(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadImagetype(selectedOptionBImage,OptionB_Photo,'OptionB_Photo')}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>


                            <p>Option C Photo</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedOptionCBaseImage && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedOptionCBaseImage}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedOptionCBaseImage(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="myOptionCImage"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedOptionCBaseImage(result);
                                        });
                                        setselectedOptionCImage(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadImagetype(selectedOptionCImage,OptionC_Photo,'OptionC_Photo')}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>

                            <p>Option D Photo</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedOptionDBaseImage && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedOptionDBaseImage}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedOptionDBaseImage(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="myOptionDImage"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedOptionDBaseImage(result);
                                        });
                                        setselectedOptionDImage(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadImagetype(selectedOptionDImage,OptionD_Photo,'OptionD_Photo')}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>

                            <p>Option E Photo</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedOptionEBaseImage && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedOptionEBaseImage}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedOptionEBaseImage(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="myOptionEImage"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedOptionEBaseImage(result);
                                        });
                                        setselectedOptionEImage(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadImagetype(selectedOptionEImage,OptionE_Photo,'OptionE_Photo')}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>
                            
                </TabPanel>
              </TabContext>
            </Box>       
                       
            </div>
           <div>
           </div>
        </Container>
      </ThemeProvider>
    );
}