import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';

import axiosInstance from '../../axiosInstance';
import moment from 'moment'
import { type } from '@testing-library/user-event/dist/type';
const roles = ['Market', 'Finance', 'Development'];
const randomRole = () => {
  return randomArrayItem(roles);
};


function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    //const CycleID = randomId();
    const CycleID =randomId();// 0;
    setRows((oldRows) => [
      ...oldRows,
      //{ CycleID, CenterName: '', EmailID: '',Password:'',MobileNo:'',City:'',Address:'',IsActive:false,isNew: true },
      { CycleID, CycleDescription: '', CycleCreateDate: '',CycleStartDate:'',CycleEndDate:'',IsActive:false},
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [CycleID]: { mode: GridRowModes.Edit, fieldToFocus: 'CycleDescription' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add cycle
      </Button>
    </GridToolbarContainer>
  );
}

export default function AddCycle() {
  const dateFormatter = new Intl.DateTimeFormat("no-NB"); // dd.mm.yyyy, could be replaced with "en-US" for mm/dd/yyyy.
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [TotalCount, setTotalCount] = React.useState(0);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  React.useEffect(() => {
    // axiosInstance.get('TrainingCenter/GetTrainingCenterMasters/?PageSize='+ paginationModel.pageSize +'&PageIndex=' +  (parseInt(paginationModel.page)+1)).then((response)=>{
    //     console.log(response.data);
    //     console.log(response.data.TotalCount)
    //     setRows(response.data.Data)
    //     setTotalCount(response.data.TotalCount)
    //    });
    console.log(randomCreatedDate());
    getDataList();
  }, [paginationModel]);
  function getDataList(){
    axiosInstance.get('Cycle/GetCycleMasters/?PageSize='+ paginationModel.pageSize +'&PageIndex=' +  (parseInt(paginationModel.page)+1)).then((response)=>{
        console.log(response.data);
        console.log(response.data.TotalCount)
        setRows(response.data.Data)
        setTotalCount(response.data.TotalCount)
       });
  }
  const handleEditClick = (CycleID) => () => {
    console.log(rows);
    console.log(CycleID)
    setRowModesModel({ ...rowModesModel, [CycleID]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (CycleID) => () => {
    setRowModesModel({ ...rowModesModel, [CycleID]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (CycleID) => () => {
    //console.log(rows);
    console.log(CycleID)
    if(!Number.isInteger(CycleID)){
        setRows(rows.filter((row) => row.CycleID !== CycleID));
    }
    // if(CycleID==0){
    //     setRows(rows.filter((row) => row.CycleID !== CycleID));
    // }
    //setRows(rows.filter((row) => row.CycleID !== CycleID));
  };

  const handleCancelClick = (CycleID) => () => {
    setRowModesModel({
      ...rowModesModel,
      [CycleID]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.CycleID === CycleID);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.CycleID !== CycleID));
    }
  };

  const processRowUpdate = (newRow) => {
    //const updatedRow = { ...newRow, isNew: false };
    const updatedRow = { ...newRow,CycleID:Number.isInteger(newRow.CycleID)?newRow.CycleID:0};
    setRows(rows.map((row) => (row.CycleID === newRow.CycleID ? updatedRow : row)));
    console.log(updatedRow);
    if(updatedRow.CycleID==0){
        axiosInstance.post('Cycle/AddCycleMaster',updatedRow,
        ).then((response)=>{
            //getVideoList();
            console.log(response.data);
            if(response.data.Status){
                alert("Added data successfully");
            }
            else{
                alert(response.data.Message)
            }
            
            getDataList();
        });
    }
    else{
        axiosInstance.post('Cycle/UpdateCycleMaster',updatedRow,
        ).then((response)=>{
            //getVideoList();
            console.log(response.data);
            if(response.data.Status){
                alert("Data updated successfully");
            }
            else{
                alert(response.data.Message)
            }
            
            getDataList();
        });
    }
   
   
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  

  const columns = [
    {
      field: 'CycleID',
      headerName: '',
      width: 150,
      renderCell: (params) => {
        return <a href={'../admin/addcyclecourse/' + params.row.CycleID}>Add / View Course</a>;
      },
    },
    { field: 'CycleDescription', headerName: 'Cycle Description', width: 250, editable: true },
    
    {
      field: 'CycleCreateDate',
      headerName: 'Created date',
      type: 'date',
      width: 120,
      editable: true,
      valueFormatter: (params) => {
        if (!params) return '';
        // Create a new Date object
        const date = new Date(params);
        // Format the date to a more readable format (e.g., "MM/DD/YYYY")
        //return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      },
    },
    {
        field: 'CycleStartDate',
        headerName: 'Start date',
        type: 'date',
        width: 120,
        editable: true ,
        // valueFormatter: ({ value }) => new Intl.DateTimeFormat('fr-CA', {
        //     year: 'numeric', month: '2-digit', day: '2-digit'
        // }).format(value),
        //format:"YYYY-MM-DD",
        //valueFormatter: (params) => moment(params?.value).format("DD-MM-YYYY")
        // valueFormatter: ({ value }) => {
        //     return value ? new Date(value).toLocaleDateString() : '';
        // },

        valueFormatter: (params) => {
            if (!params) return '';
            // Create a new Date object
            const date = new Date(params);
            // Format the date to a more readable format (e.g., "MM/DD/YYYY")
            //return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
          },
      },
    {
        field: 'CycleEndDate',
        headerName: 'End date',
        type: 'date',
        width: 120,
        editable: true ,
        // valueFormatter: ({ value }) => new Intl.DateTimeFormat('fr-CA', {
        //     year: 'numeric', month: '2-digit', day: '2-digit'
        // }).format(value),
        // format:"YYYY-MM-DD",
        //valueFormatter: (params) => moment(params?.value).format("DD-MM-YYYY")
        valueFormatter: (params) => {
            if (!params) return '';
            // Create a new Date object
            const date = new Date(params);
            // Format the date to a more readable format (e.g., "MM/DD/YYYY")
            //return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
          },
    },
    {
        field: 'IsActive',
        headerName: 'Status',
        width: 80,
        align: 'left',
        headerAlign: 'left',
        type: 'boolean',
        editable: true 
      },
    // {
    //   field: 'role',
    //   headerName: 'Department',
    //   width: 220,
    //   editable: true,
    //   type: 'singleSelect',
    //   valueOptions: ['Market', 'Finance', 'Development'],
    // },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.CycleID}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        rowCount={TotalCount || 0}
        pageSizeOptions={[1,5,10]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
      />
    </Box>
  );
}
