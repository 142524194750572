import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditSingleSelectCell,
  useGridApiContext,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';

import axiosInstance from '../../axiosInstance';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;
  const handleClick = () => {
    const CycleCourseID =randomId();// 0;
    setRows((oldRows) => [
      ...oldRows,
      { CycleCourseID,  CourseName:'',  IsActive:false},
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [CycleCourseID]: { mode: GridRowModes.Edit, fieldToFocus: 'CourseName' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add Course for cycle
      </Button>
    </GridToolbarContainer>
  );
}

function CourseColumnEditComponent(props) {
    const apiRef = useGridApiContext();
    console.log(props)
    const handleValueChange = async () => {
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: 'SubjectName',
        value: '',
      });
      
    };
  
    return (
      <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />
    );
  }

  
  

export default function AddCycleCourse() {
  let { id} = useParams();
  const initialRef = React.useRef(true);
  const [questions, setQuestions] = React.useState([]);
  const [totalmarks, setTotalMarks] = React.useState(0);
  const [totalquestions, setTotalQuestions] = React.useState(0);
  const [cyclename, setCycleName] = React.useState('');
  const [courses, setCourses] = React.useState([]);
  const [subjects, setSubjects] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [TotalCount, setTotalCount] = React.useState(0);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  React.useEffect(() => {
    console.log('CycleCourseID')
    console.log(id)
    console.log(initialRef.current);
    if (initialRef.current) {
      getCourseListByCycle();
    }
    initialRef.current = false;
    getDataList();
  }, [paginationModel]);
  
  function getCourseListByCycle(){
    axiosInstance.get('Common/GetCourseListByCycle?id=' + id).then((response)=>{
      console.log('getCourseListByCycle');
      console.log(response.data);
      console.log(response.data['Table']);
      console.log(response.data['Table1']);
      if(response.data.length !== 0){
        setCourses(response.data['Table'])
        setCycleName(response.data['Table1'][0].CycleDescription)
      }
     });
  }
  function getDataList(){
    axiosInstance.get('CycleCourse/GetCycleCourseList/?PageSize='+ paginationModel.pageSize +'&PageIndex=' +  (parseInt(paginationModel.page)+1) + '&CycleID=' + id).then((response)=>{
        console.log(response.data);
        if(response.data.Status){
          console.log(response.data.TotalCount)
          setRows(response.data.Data)
          setTotalCount(response.data.TotalCount)
        }
       });
  }
  const handleEditClick = (CycleCourseID) => () => {
    console.log(rows);
    console.log(CycleCourseID)
    setRowModesModel({ ...rowModesModel, [CycleCourseID]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (CycleCourseID) => () => {
    setRowModesModel({ ...rowModesModel, [CycleCourseID]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (CycleCourseID) => () => {
    //console.log(rows);
    console.log(CycleCourseID)
    if(!Number.isInteger(CycleCourseID)){
        setRows(rows.filter((row) => row.CycleCourseID !== CycleCourseID));
    }
  };

  const handleCancelClick = (CycleCourseID) => () => {
    setRowModesModel({
      ...rowModesModel,
      [CycleCourseID]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.CycleCourseID === CycleCourseID);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.CycleCourseID !== CycleCourseID));
    }
  };
  const validateRows = (row) => {
    
    
    console.log(row.Course)
    if (row.Course == '') {
        alert("Please select Course");
        return false;
    }
    // let newcourse = courses.filter(function (el) {return el.CourseName==row.CourseName});
    // let newcycle = cycles.filter(function (el) {return el.CycleDescription==row.CycleDescription});
    // console.log(newcourse[0].CourseId)
    // row.CourseID=newcourse[0].CourseId;
    // row.CycleID=newcycle[0].CycleId;
    // console.log(row)
    // if (row.Name.trim() == '') {
    //   alert("Please enter name");
    //   return false;
    // }
    return true;
    
  };
  // const validateRows = (row) => {
    
  //   // rows.forEach((row) => {
  //   //   console.log(row)
  //   //   if (!row.Name) newInvalidCells[row.CycleCourseID] = { ...newInvalidCells[row.CycleCourseID], Name: true };
  //   //   //if (!row.email) newInvalidCells[row.id] = { ...newInvalidCells[row.id], email: true };
  //   // });
  //   const newInvalidCells = {};
  //   if (!row.Name) newInvalidCells[row.CycleCourseID] = { ...newInvalidCells[row.CycleCourseID], Name: true };
  //   setInvalidCells(newInvalidCells);
  //   console.log(invalidCells)
  // };
  const processRowUpdate = (newRow) => {
    //validateRows(newRow);
    //const isValid = validateRow(newRow);
    const isValid = validateRows(newRow);
    if (isValid) {
        newRow.error = false;
        //const updatedRow = { ...newRow, isNew: false };
        const updatedRow = { ...newRow,CycleCourseID:Number.isInteger(newRow.CycleCourseID)?newRow.CycleCourseID:0};
        setRows(rows.map((row) => (row.CycleCourseID === newRow.CycleCourseID ? updatedRow : row)));
        //console.log(updatedRow);
        let newcourse = courses.filter(function (el) {return el.CourseName==newRow.CourseName});
        console.log(newcourse)
        updatedRow.CourseID=newcourse[0].CourseID;
        console.log(updatedRow)
        
        var data={
            "CycleCourseID":updatedRow.CycleCourseID,
            "CycleID":id,
            "CourseID":updatedRow.CourseID,
            "IsActive":updatedRow.IsActive
        };
        console.log(data)
        axiosInstance.post('CycleCourse/AddCycleCourse',data,
        ).then((response)=>{
            //console.log(response.data);
            if(response.data.Status){
                alert(response.data.Message);
            }
            else{
                alert(response.data.Message)
            }
            getDataList();
        });
        
        return updatedRow;
    } else {
      newRow.error = true; // Mark as invalid
      alert('Required field is empty!'); // Error handling
      const updatedRow = { ...newRow,isNew:true};
      setRows(rows.map((row) => (row.CycleCourseID === newRow.CycleCourseID ? updatedRow : row)));
      return updatedRow; // Prevent the update
    };
    
  };
  const ProcessRowUpdateError = (error) => {
    console.log(error);
  };

  
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  
  const columns = [
    { field: 'index',headerName: 'No.'},
    { field: 'CourseName', headerName: 'Course', width: 400, editable: true ,type: 'singleSelect',valueOptions: courses.map(function(a) {return a.CourseName;})},
    { field: 'IsActive',headerName: 'IsActive',width: 80,align: 'left',headerAlign: 'left',type: 'boolean',editable: true},
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
  ];
  // Prepend row index to each row
  const rowsWithIndex = rows.map((row, index) => ({
    ...row,
    index: ( index+ (paginationModel.page*paginationModel.pageSize)) + 1, // 1-based index
  }));
  
  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
   
    <Table aria-label="simple table">
      <TableBody>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
            <TableCell>
            Cycle : {cyclename}
            </TableCell>
        </TableRow>
        </TableBody>
    </Table>
    <br></br>
     {/* <div>Event Name:{questions[0].EventName}</div> */}
      <DataGrid
        rows={rowsWithIndex}
        columns={columns}
        getRowId={(row) => row.CycleCourseID}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={ProcessRowUpdateError}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        rowCount={TotalCount || 0}
        pageSizeOptions={[5,50,100,500]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        // getCellClassName={(params) => 
        //   params.error? 'errorCell' : ''
        // }
      />
    </Box>
  );
}
