import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Field, Form, Formik, useFormik } from 'formik';
import { Container, Box, Typography, TextField, Button, CssBaseline, FormControl, InputLabel, Select, MenuItem, Grid, FormControlLabel, Checkbox, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { ArrowBack, Block, CheckBox } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import axiosInstance from '../../axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import uploadfile_service from '../../services/uploadfile_service';
export default function AddSlide(){
  const defaultTheme = createTheme();
  let { id} = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const [tabDisabled,settabDisabled] =useState(true);
  const [IsActive, setIsActive] = useState(false);
  const [selectedSlideBaseImage, setselectedSlideBaseImage] = useState(null);
  const [selectedSlideImage, setselectedSlideImage] = useState(null);
  const [imageid,setimageid]=useState(0);

  const [selectedSlideBaseImageSM, setselectedSlideBaseImageSM] = useState(null);
  const [selectedSlideImageSM, setselectedSlideImageSM] = useState(null);
  const [imageidsm,setimageidsm]=useState(0);
  
async function displayImageByName(
    filename
  ) {
    // Fetch the image.
    var date = new Date();
    axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
      console.log(response);
      //const imageElement = document.getElementById(imageId);
      //imageElement.src = response.data;
      setselectedSlideBaseImage(response.data);
     });
  };
  async function displayImageByNameSM(
    filename
  ) {
    // Fetch the image.
    var date = new Date();
    axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
      console.log(response);
      setselectedSlideBaseImageSM(response.data);
     });
  };
  useEffect((event)=>{
    console.log("id");
    console.log(id);
    if(id != undefined || id != null){
        settabDisabled(false);
        axiosInstance.get('Slide/GetSlideMaster/' + id).then((response)=>{
            //console.log(response.data);
            //console.log(response.data.Data[0]);
            var ndata=response.data.Data[0];
            console.log('ndata');
            console.log(ndata);
            addform.values.SlideName=ndata.SlideName;
            addform.values.OrderNo=ndata.OrderNo;
            addform.values.ImageId=ndata.ImageID;
            addform.values.ImageIdsm=ndata.ImageIDSM;
            addform.values.IsActive=ndata.IsActive;
            setIsActive(ndata.IsActive);
            setimageid(ndata.ImageID);
            setimageidsm(ndata.ImageIDSM);
            if(ndata.ImageName.trim() !== ""){
                displayImageByName(ndata.ImageName);
            }
            if(ndata.ImageNameSM.trim() !== ""){
                displayImageByNameSM(ndata.ImageNameSM);
            }
           });
    }
  },[]);

  const addform = useFormik({
    initialValues: {
        SlideName: '',
        ImageId:0,
        ImageIdsm:0,
        OrderNo:'',
        IsActive:true
    },
    validationSchema: Yup.object({
        SlideName: Yup.string().required('Name is required')
    }),
    onSubmit: (values) => {
        console.log(values)
        if(id===undefined || id ===null){
            axiosInstance.post('Slide/AddSlideMaster',{
                "SlideId": 0,
                "SlideName": values.SlideName,
                "ImageId": values.ImageId,
                "ImageIdsm": values.ImageIdsm,
                "OrderNo":values.OrderNo,
                "IsActive": values.IsActive
            },
            ).then((response)=>{
                //getVideoList();
                console.log(response.data);
                alert("Added Slide successfully")
                
            });
        }
        else{
            axiosInstance.post('Slide/UpdateSlideMaster',{
                "SlideId": parseInt(id),
                "SlideName": values.SlideName,
                "ImageId": values.ImageId,
                "ImageIdsm": values.ImageIdsm,
                "OrderNo":values.OrderNo,
                "IsActive": values.IsActive
            },
            ).then((response)=>{
                //getVideoList();
                console.log(response.data);
                alert("Updated Slide successfully")
                
            });
        }
       
    }
  });
  const routeChange = () =>{ 
    navigate('/admin/slidelist');
  }
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }; 
  const handleIsActiveChange = (event) => {
    //console.log(event.target.value);
    setIsActive(IsActive?false:true)
    addform.values.IsActive=IsActive?false:true;
    //console.log(IsActive)
    //console.log(IsActive)
    //setIsActive(event.target.value)
    //setBusinessTypeId(event.target.value);
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  };
  const UploadSlideImage = event => {
    console.log('id:' + id);
    console.log('imageid:' + imageid);
    //uploadfile_service.UploadSlideImage(selectedSlideImage,id,imageid).then((data)=>{
    uploadfile_service.UploadSlideImageType(selectedSlideImage,id,imageid,2).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  const UploadSlideImageSM = event => {
    console.log('id:' + id);
    console.log('imageidsm:' + imageidsm);
    console.log('selectedSlideImageSM');
    console.log(selectedSlideImageSM);
    uploadfile_service.UploadSlideImageType(selectedSlideImageSM,id,imageidsm,1).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  
    return(
        <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          <CssBaseline />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               <Grid item xs={6}>
                  <Button 
                    style={{width:"150px"}}
                    startIcon={<ArrowBack />} onClick={routeChange}>
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="h6" variant="h6">
                      Add slide
                    </Typography>
                </Grid>
          </Grid>
          
          
            <div style={{textAlign:'left'}}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    <Tab label="Slide detail" value="1" />
                    <Tab label="Image" value="2" disabled={tabDisabled}/>
                  </TabList>
                </Box>
                <TabPanel value="1">
                <Box
                    component="form"
                    // sx={{
                    //   '& .MuiTextField-root': { m: 1, width: '35ch' },
                    // }}
                    onSubmit={addform.handleSubmit}
                  >
                  <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                      <Grid item xs={2} sm={4} md={4}>
                      <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                name="SlideName"
                                id="SlideName"
                                placeholder="Slide Name"
                                value={addform.values.SlideName}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                                error={addform.errors.SlideName && addform.touched.SlideName ?true:false}
                                helperText={addform.errors.SlideName && addform.touched.SlideName ?addform.errors.SlideName:null}
                              />
                              
                      </Grid>
                      <Grid  item xs={2} sm={4} md={4}>
                      <TextField
                                fullWidth
                                type="number"
                                autoComplete="off"
                                name="OrderNo"
                                id="OrderNo"
                                placeholder="Order No"
                                value={addform.values.OrderNo}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                              />
                      </Grid>
                      <Grid item xs={2} sm={4} md={4} style={{textAlign:'left',paddingLeft:'15px'}}>
                          
                          <FormControlLabel control={
                            <Checkbox
                            value={addform.values.IsActive}
                            checked={addform.values.IsActive}
                            onChange={handleIsActiveChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          } name='IsActive'
                          label="IsActive"
                          labelPlacement='end'>,

                          </FormControlLabel>
                          
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                  </Grid>
                  <br></br>
                  <Button
                    type="submit"
                    variant="contained"
                    //sx={{ mt: 1, mb: 1 ,m:1}}
                    style={{width:"150px"}}
                  >
                    Save 
                  </Button>
                  </Box>
                  
                </TabPanel>
                
                <TabPanel value="2">
                      <p>Large Image</p>
                      <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                         <Grid item xs={2} sm={4} md={6}>
                            {selectedSlideBaseImage && (
                                <div>
                                  <img
                                    alt="not found"
                                    width={"250px"}
                                    //src={URL.createObjectURL(selectedCategoryImage)}
                                    src={selectedSlideBaseImage}
                                  />
                                  <br />
                                  <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedSlideBaseImage(null)}>Remove</Button>
                                </div>
                              )}
                          </Grid>
                          <Grid item xs={2} sm={4} md={6}>
                              <input
                                type="file"
                                name="mySlideImage"
                                variant="contained"
                                onChange={(event) => {
                                  
                                  getBase64(event.target.files[0]).then((result)=>{
                                    setselectedSlideBaseImage(result);
                                  });
                                  setselectedSlideImage(event.target.files[0]);
                                  console.log(event.target.files[0]);
                                }}
                              />
                              
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 1}}
                                style={{width:"150px"}}
                                onClick={UploadSlideImage}
                              >
                                Upload 
                              </Button>
                          </Grid>
                      </Grid>
                      <p>Small Image</p>
                      <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                         <Grid item xs={2} sm={4} md={6}>
                            {selectedSlideBaseImageSM && (
                                <div>
                                  <img
                                    alt="not found"
                                    width={"250px"}
                                    //src={URL.createObjectURL(selectedCategoryImage)}
                                    src={selectedSlideBaseImageSM}
                                  />
                                  <br />
                                  <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedSlideBaseImageSM(null)}>Remove</Button>
                                </div>
                              )}
                          </Grid>
                          <Grid item xs={2} sm={4} md={6}>
                              <input
                                type="file"
                                name="mySlideImageSM"
                                variant="contained"
                                onChange={(event) => {
                                  
                                  getBase64(event.target.files[0]).then((result)=>{
                                    setselectedSlideBaseImageSM(result);
                                  });
                                  setselectedSlideImageSM(event.target.files[0]);
                                  console.log(event.target.files[0]);
                                }}
                              />
                              
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 1}}
                                style={{width:"150px"}}
                                onClick={UploadSlideImageSM}
                              >
                                Upload 
                              </Button>
                          </Grid>
                      </Grid>
                </TabPanel>
              </TabContext>
            </Box>       
                       
            </div>
           <div>
           </div>
        </Container>
      </ThemeProvider>
    );
}