import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditSingleSelectCell,
  useGridApiContext
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';

import axiosInstance from '../../axiosInstance';
import { makeStyles } from '@mui/styles';
import callFormDataService from '../../services/fordata_service';
import { type } from '@testing-library/user-event/dist/type';
import { number } from 'yup';

const useStyles = makeStyles({
  errorCell: {
    'background-color': 'rgb(201, 76, 76);', // Light red background for errors
  },
});
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    //const EnrollmentID = randomId();
    const EnrollmentID =randomId();// 0;
    setRows((oldRows) => [
      ...oldRows,
      { EnrollmentID,CenterName:'', CycleDescription: '',CourseName:'',EnrollmentNo:'', EnrollmentDate: '',Name:'',
        EmailID:'',MobileNo:'',City:'',Address:'',UserName:'',Password:'',CancelDate:'',CancellationReason:'',IsActive:false},
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [EnrollmentID]: { mode: GridRowModes.Edit, fieldToFocus: 'CenterName' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add Student
      </Button>
    </GridToolbarContainer>
  );
}
function CycleColumnEditComponent(props) {
  const apiRef = useGridApiContext();
  console.log(props)
  const handleValueChange = async () => {
    await apiRef.current.setEditCellValue({
      id: props.id,
      field: 'CourseName',
      value: '',
    });
    
  };

  return (
    <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />
  );
}
export default function RegisterStudent() {
  const classes = useStyles();
  const [invalidCells, setInvalidCells] = React.useState({});
  const initialRef = React.useRef(true);
  const [courses, setCourses] = React.useState([]);
  const [cycles, setCycles] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [TotalCount, setTotalCount] = React.useState(0);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  React.useEffect(() => {
    console.log(initialRef.current);
    if (initialRef.current) {
      getCycleAndCourseList();
    }
    initialRef.current = false;
    getDataList();
  }, [paginationModel]);
  function getCycleAndCourseList(){
    axiosInstance.get('common/GetCycleList').then((response)=>{
      console.log('GetCycleList');
      console.log(response.data);
      setCycles(response.data)
     });
     axiosInstance.get('common/GetCycleCourseList').then((response)=>{
      console.log('GetCycleCourseList');
      console.log(response.data);
      setCourses(response.data)
     });
  }
  function getDataList(){
    axiosInstance.get('Student/GetStudentList/?PageSize='+ paginationModel.pageSize +'&PageIndex=' +  (parseInt(paginationModel.page)+1)).then((response)=>{
        console.log(response.data);
        console.log(response.data.TotalCount)
        setRows(response.data.Data)
        setTotalCount(response.data.TotalCount)
       });
  }
  const handleEditClick = (EnrollmentID) => () => {
    console.log(rows);
    console.log(EnrollmentID)
    setRowModesModel({ ...rowModesModel, [EnrollmentID]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (EnrollmentID) => () => {
    setRowModesModel({ ...rowModesModel, [EnrollmentID]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (EnrollmentID) => () => {
    //console.log(rows);
    console.log(EnrollmentID)
    if(!Number.isInteger(EnrollmentID)){
        setRows(rows.filter((row) => row.EnrollmentID !== EnrollmentID));
    }
  };

  const handleCancelClick = (EnrollmentID) => () => {
    setRowModesModel({
      ...rowModesModel,
      [EnrollmentID]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.EnrollmentID === EnrollmentID);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.EnrollmentID !== EnrollmentID));
    }
  };
  const validateRows = (row) => {
    console.log(row)
    if (row.CycleDescription.trim() === '') {
      alert("Please select cycle");
      return false;
    }
    if (row.CourseName.trim() === '') {
      alert("Please select course");
      return false;
    }
    if (row.EnrollmentDate === null || row.EnrollmentDate === '') {
      alert("Please enter entrollment date");
      return false;
    }
    // let newcourse = courses.filter(function (el) {return el.CourseName==row.CourseName});
    // let newcycle = cycles.filter(function (el) {return el.CycleDescription==row.CycleDescription});
    // console.log(newcourse[0].CourseId)
    // row.CourseID=newcourse[0].CourseId;
    // row.CycleID=newcycle[0].CycleId;
    // console.log(row)
    if (row.Name.trim() === '') {
      alert("Please enter name");
      return false;
    }
    return true;
    
  };
  // const validateRows = (row) => {
    
  //   // rows.forEach((row) => {
  //   //   console.log(row)
  //   //   if (!row.Name) newInvalidCells[row.EnrollmentID] = { ...newInvalidCells[row.EnrollmentID], Name: true };
  //   //   //if (!row.email) newInvalidCells[row.id] = { ...newInvalidCells[row.id], email: true };
  //   // });
  //   const newInvalidCells = {};
  //   if (!row.Name) newInvalidCells[row.EnrollmentID] = { ...newInvalidCells[row.EnrollmentID], Name: true };
  //   setInvalidCells(newInvalidCells);
  //   console.log(invalidCells)
  // };
  const processRowUpdate = (newRow) => {
    //validateRows(newRow);
    //const isValid = validateRow(newRow);
    const isValid = validateRows(newRow);
    if (isValid) {
        newRow.error = false;
        //const updatedRow = { ...newRow, isNew: false };
        const updatedRow = { ...newRow,EnrollmentID:Number.isInteger(newRow.EnrollmentID)?newRow.EnrollmentID:0};
        setRows(rows.map((row) => (row.EnrollmentID === newRow.EnrollmentID ? updatedRow : row)));
        //console.log(updatedRow);
        let newcourse = courses.filter(function (el) {return el.CourseName===newRow.CourseName});
        let newcycle = cycles.filter(function (el) {return el.CycleDescription===newRow.CycleDescription});
        updatedRow.CourseID=newcourse[0].CourseID;
        updatedRow.CycleID=newcycle[0].CycleId;
        console.log(updatedRow)
        var data={
          "Name":updatedRow.Name,
          "Password":updatedRow.Password,
          "MobileNo":updatedRow.MobileNo,
          "IsActive":updatedRow.IsActive,
          "EmailID":updatedRow.EmailID,
          "City":updatedRow.City,
          "Address":updatedRow.Address,
          "EnrollmentID":updatedRow.EnrollmentID,
          "CourseID":updatedRow.CourseID,
          "CycleID":updatedRow.CycleID,
          "EnrollmentDate":updatedRow.EnrollmentDate,
          "CancelDate":updatedRow.CancelDate,
          "CancellationReason":updatedRow.CancellationReason
        };
        //console.log(data)
        axiosInstance.post('Student/AddStudentMaster',data,
        ).then((response)=>{
            //console.log(response.data);
            if(response.data.Status){
                alert(response.data.Message);
            }
            else{
                alert(response.data.Message)
            }
            getDataList();
        });
        
        return updatedRow;
    } else {
      newRow.error = true; // Mark as invalid
      alert('Required field is empty!'); // Error handling
      const updatedRow = { ...newRow,isNew:true};
      setRows(rows.map((row) => (row.EnrollmentID === newRow.EnrollmentID ? updatedRow : row)));
      return updatedRow; // Prevent the update
    };
    
  };
  const ProcessRowUpdateError = (error) => {
    console.log(error);
  };
  
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  
  const columns = [
    //{ field: 'CenterName', headerName: 'Center', width: 100, editable: false },
    { field: 'CycleDescription', headerName: 'Cycle', width: 200, editable: true , type: 'singleSelect',valueOptions: cycles.map(function(a) {return a.CycleDescription;}),
      renderEditCell: (params) => <CycleColumnEditComponent {...params} />
    },
    //{ field: 'CourseName', headerName: 'Course', width: 200, editable: true ,type: 'singleSelect',valueOptions: courses.map(function(a) {return a.CourseName;})},
    { field: 'CourseName', headerName: 'Course', width: 150, editable: true , type: 'singleSelect',
      valueOptions: ({ row }) => {
          if (!row) {
            return courses.map(function(a) {return a.CourseName;});
          }
          if(row.CycleDescription !==''){
              let newcourses = courses.filter(function (el) {return el.CycleDescription===row.CycleDescription});
              return newcourses.map(function(a) {return a.CourseName;});
          }
          else{
              return courses.map(function(a) {return a.CourseName;});
          }
        },
  },
    { field: 'EnrollmentNo', headerName: 'Enrollment no', width: 150, editable: false },
    { field: 'Name', headerName: 'Name', width: 100, editable: true },
    { field: 'EmailID', headerName: 'Email', width: 100, editable: true },
    { field: 'MobileNo', headerName: 'Mobile no',type:'number', width: 100, editable: true },
    { field: 'City', headerName: 'City', width: 100, editable: true },
    { field: 'Address', headerName: 'Address', width: 150, editable: true },
    { field: 'UserName', headerName: 'UserName', width: 100, editable: false },
    { field: 'Password', headerName: 'Password', width: 100, editable: true },
    {
      field: 'EnrollmentDate',
      headerName: 'Enrollment date',
      type: 'date',
      width: 120,
      editable: true,
      valueFormatter: (params) => {
        if (!params) return '';
        // Create a new Date object
        const date = new Date(params);
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      },
    },
    {
        field: 'CancelDate',
        headerName: 'Cancel date',
        type: 'date',
        width: 120,
        editable: true ,
        valueFormatter: (params) => {
            if (!params) return '';
            // Create a new Date object
            const date = new Date(params);
            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
          },
      },
      { field: 'CancellationReason', headerName: 'Cancellation reason', width: 150, editable: true },
      {
          field: 'IsActive',
          headerName: 'Status',
          width: 80,
          align: 'left',
          headerAlign: 'left',
          type: 'boolean',
          editable: true 
        },
    // {
    //   field: 'role',
    //   headerName: 'Department',
    //   width: 220,
    //   editable: true,
    //   type: 'singleSelect',
    //   valueOptions: ['Market', 'Finance', 'Development'],
    // },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.EnrollmentID}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={ProcessRowUpdateError}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        rowCount={TotalCount || 0}
        pageSizeOptions={[1,5,10]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        // getCellClassName={(params) => 
        //   params.error? 'errorCell' : ''
        // }
      />
    </Box>
  );
}
