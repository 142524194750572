import axiosInstance from '../axiosInstance';

class callFormDataService {
    CallApi(apipoint,inputvalue,Type) {
      let formData = new FormData();
      formData.append("inputvalue", inputvalue);
      formData.append("Type", Type);
      return axiosInstance.post(apipoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
    }
}
export default new callFormDataService();