// CustomCard.js
import React from 'react';
import { Card, CardContent, CardMedia, Typography, CardActions, Button, Grid2, Grid, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DashboardCard = ({ text, value, url }) => {
    let navigate =useNavigate();
  
    const Gotolink=(url)=>{
      navigate(url);
    }
  return (
             <Grid item xs={2} sm={4} md={4}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          {text}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {value}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button size="small" color="primary" onClick={()=> Gotolink(url)} >
                        Go to list
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
  );
};

export default DashboardCard;
