import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';

import axiosInstance from '../../axiosInstance';
import moment from 'moment'
import { type } from '@testing-library/user-event/dist/type';
const roles = ['Market', 'Finance', 'Development'];
const randomRole = () => {
  return randomArrayItem(roles);
};

const initialRows = [
  {
    id: randomId(),
    name: randomTraderName(),
    age: 25,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
  {
    id: randomId(),
    name: randomTraderName(),
    age: 36,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
  {
    id: randomId(),
    name: randomTraderName(),
    age: 19,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
  {
    id: randomId(),
    name: randomTraderName(),
    age: 28,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
  {
    id: randomId(),
    name: randomTraderName(),
    age: 23,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
];


function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    //const CenterID = randomId();
    const CenterID =randomId();// 0;
    setRows((oldRows) => [
      ...oldRows,
      //{ CenterID, CenterName: '', EmailID: '',Password:'',MobileNo:'',City:'',Address:'',IsActive:false,isNew: true },
      { CenterID, CenterName: '', EmailID: '',Password:'',MobileNo:'',City:'',Address:'',IsActive:false},
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [CenterID]: { mode: GridRowModes.Edit, fieldToFocus: 'CenterName' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add training center
      </Button>
    </GridToolbarContainer>
  );
}

export default function AddTrainingCenter() {
  const dateFormatter = new Intl.DateTimeFormat("no-NB"); // dd.mm.yyyy, could be replaced with "en-US" for mm/dd/yyyy.
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [TotalCount, setTotalCount] = React.useState(0);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  React.useEffect(() => {
    // axiosInstance.get('TrainingCenter/GetTrainingCenterMasters/?PageSize='+ paginationModel.pageSize +'&PageIndex=' +  (parseInt(paginationModel.page)+1)).then((response)=>{
    //     console.log(response.data);
    //     console.log(response.data.TotalCount)
    //     setRows(response.data.Data)
    //     setTotalCount(response.data.TotalCount)
    //    });
    getDataList();
  }, [paginationModel]);
  function getDataList(){
    axiosInstance.get('TrainingCenter/GetTrainingCenterMasters/?PageSize='+ paginationModel.pageSize +'&PageIndex=' +  (parseInt(paginationModel.page)+1)).then((response)=>{
        console.log(response.data);
        console.log(response.data.TotalCount)
        setRows(response.data.Data)
        setTotalCount(response.data.TotalCount)
       });
  }
  const handleEditClick = (CenterID) => () => {
    console.log(rows);
    console.log(CenterID)
    setRowModesModel({ ...rowModesModel, [CenterID]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (CenterID) => () => {
    setRowModesModel({ ...rowModesModel, [CenterID]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (CenterID) => () => {
    //console.log(rows);
    console.log(CenterID)
    if(!Number.isInteger(CenterID)){
        setRows(rows.filter((row) => row.CenterID !== CenterID));
    }
    // if(CenterID==0){
    //     setRows(rows.filter((row) => row.CenterID !== CenterID));
    // }
    //setRows(rows.filter((row) => row.CenterID !== CenterID));
  };

  const handleCancelClick = (CenterID) => () => {
    setRowModesModel({
      ...rowModesModel,
      [CenterID]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.CenterID === CenterID);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.CenterID !== CenterID));
    }
  };

  const processRowUpdate = (newRow) => {
    //const updatedRow = { ...newRow, isNew: false };
    const updatedRow = { ...newRow,CenterID:Number.isInteger(newRow.CenterID)?newRow.CenterID:0};
    setRows(rows.map((row) => (row.CenterID === newRow.CenterID ? updatedRow : row)));
    console.log(updatedRow);
    axiosInstance.post('TrainingCenter/RegisterTrainingCenter',updatedRow,
    ).then((response)=>{
        //getVideoList();
        console.log(response.data);
        alert(updatedRow.CenterID==0?"Added data successfully":"Data updated successfully");
        getDataList();
    });
   
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  

  const columns = [
    { field: 'CenterName', headerName: 'Center name', width: 250, editable: true },
    {
      field: 'EmailID',
      headerName: 'Email id',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true 
    },
    {
        field: 'Password',
        headerName: 'Password',
        width: 100,
        align: 'left',
        headerAlign: 'left',
        editable: true 
      },
      {
        field: 'City',
        headerName: 'City',
        width: 100,
        align: 'left',
        headerAlign: 'left',
        editable: true 
      },
      {
        field: 'Address',
        headerName: 'Address',
        width: 200,
        align: 'left',
        headerAlign: 'left',
        editable: true 
      },
      {
        field: 'MobileNo',
        headerName: 'Mobile no',
        width: 100,
        align: 'left',
        headerAlign: 'left',
        editable: true 
      },
    {
        field: 'IsActive',
        headerName: 'Status',
        width: 80,
        align: 'left',
        headerAlign: 'left',
        type: 'boolean',
        editable: true 
      },
    {
      field: 'CreatedDate',
      headerName: 'Created date',
      //type: 'date',
      width: 120,
    //   valueFormatter: params => 
    //     moment(params?.value).format("DD/MM/YYYY"),
      //editable: true,
      //valueFormatter: (value) => dateFormatter.format(value),
    },
    // {
    //   field: 'role',
    //   headerName: 'Department',
    //   width: 220,
    //   editable: true,
    //   type: 'singleSelect',
    //   valueOptions: ['Market', 'Finance', 'Development'],
    // },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.CenterID}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        rowCount={TotalCount || 0}
        pageSizeOptions={[1,5,10]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
      />
    </Box>
  );
}
