import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
//import {apiurl} from '../constant'
import { useNavigate } from "react-router-dom";
import { signin_validationSchema } from './validations/signin_validationSchema';
import { useFormik } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://sipsindia.org/">
        Sips Education
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn()  {
  localStorage.setItem("token",null);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: ""
  };
  const apiurl = process.env.REACT_APP_API_URL;
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: signin_validationSchema,
      validateOnChange: true,
      validateOnBlur: false,
      //// By disabling validation onChange and onBlur formik will validate on submit.
      onSubmit: (values, action) => {
        console.log("values:", values);
        console.log(values.email);
        console.log(values.password);
        if (!loading){
          setLoading(true);
        }
        axios({
          // Endpoint to send files
          url: apiurl + "Auth/Login",
          withCredentials:false,
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
          },
          // Attaching the form data
          data: {
              "username": values.email,
              "password": values.password
            },
          })
          // Handle the response from backend here
          .then((res) => { 
              //console.log(res);
              //console.log(res.data.Data);
              if(res.data.Status){
                localStorage.setItem("token",res.data.Data);
                setLoading(false);
                navigate('/admin')
              }
              else{
                setLoading(false);
                alert(res.data.Message);
                console.log(res.data.Message);
                action.resetForm();
              }
              
          })
          // Catch errors if any
          .catch((err) => {
              console.log(err)
              setLoading(false);
           });
        //// to get rid of all the values after submitting the form
        action.resetForm();
      },
    });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& .MuiTextField-root': { m: 1 },
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      type="email"
                      autoComplete="off"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.email && touched.email ?true:false}
                      helperText={errors.password && touched.password ?errors.email:null}
                    />
                    
                    
                     <TextField
                      fullWidth
                      type="password"
                      autoComplete="off"
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.password && touched.password ?true:false}
                      helperText={errors.password && touched.password ?errors.password:null}
                    />
                     
                    
            {/* <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            /> */}
            {/* {loading && (
              <CircularProgress
                size={68}
                sx={{
                  color:'green',
                  position: 'absolute',
                  top: -6,
                  left: -6,
                  zIndex: 1,
                }}
              />
            )} */}
            
              {loading && (
                <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
                </div>
              )}
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 ,m:1}}
            >
              Sign In
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        

        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}