import * as React from 'react';

import { Box, Button, Container, CssBaseline, Grid,  Link,  ListItemButton,  ListItemIcon,  ListItemText,  MenuItem,  Typography } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import { string } from 'yup';
import { useNavigate } from 'react-router-dom';


const defaultTheme = createTheme();
export default function SubjectList (){
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [SubjectList,setSubjectList]= React.useState([]);
  const [totalPage, settotalPage] = React.useState(0);
  const navigate = useNavigate();

  const routeChange = () =>{ 
    navigate('/admin/addSubject');
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getListData(rowsPerPage,newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getListData(+event.target.value,0);
  };
  function getListData(nrowsPerPage,npage){
    axiosInstance.get('Subject/GetSubjectMasters/?PageSize='+ nrowsPerPage +'&PageIndex=' +  (parseInt(npage)+1)).then((response)=>{
      console.log(response.data);
      console.log(response.data.Data);
      console.log(response.data.TotalCount)
      setSubjectList(response.data.Data);
      settotalPage(response.data.TotalCount);
     });
  }
  useEffect(()=>{
    // axiosInstance.get('Subject/GetSubjectMasters/?PageSize='+ rowsPerPage +'&PageIndex=' +  (parseInt(page)+1)).then((response)=>{
    //   console.log(response.data);
    //   setSubjectList(response.data);
    //  });
    getListData(rowsPerPage,page);
  },[])
    return(

      <ThemeProvider theme={defaultTheme}>
          <Container component="main" >
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& .MuiTextField-root': { m: 1 },
              }}
            >
              
              
              <Box sx={{ width: '100%' }}>
              
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={6}>
                    <Button 
                      startIcon={<AddIcon />} onClick={routeChange}>
                      Add Subject
                    </Button>
                    
                  </Grid>
                  <Grid item xs={6}>
                      <Typography component="h6" variant="h6">
                        Subject list
                      </Typography>
                    
                  </Grid>
                  <Grid item xs={6}>
                  
                  </Grid>
                  <Grid item xs={6}>
                  
                  </Grid>
                  <Grid item xs={12}>
                  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                      <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {/* {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))} */}
                              <TableCell>
                                SNo
                              </TableCell>
                              <TableCell>
                                Course
                              </TableCell>
                              <TableCell>
                                Subject
                              </TableCell>
                              <TableCell>
                                Subject Desc
                              </TableCell>
                              <TableCell>
                                Status
                              </TableCell>
                              <TableCell>
                                
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {SubjectList?.map((item,index)=>(
                              
                              <TableRow key={item.SubjectID}>
                                  <TableCell>
                                    {index+1}
                                  </TableCell>
                                  <TableCell>
                                    {item.CourseName}
                                  </TableCell>
                                  <TableCell>
                                    {/* {item.SubjectName} */}
                                    {item.SubjectName}
                                  </TableCell>
                                  <TableCell>
                                    {item.SubjectDesc}
                                  </TableCell>
                                  <TableCell>
                                    {String(item.IsActive)}
                                  </TableCell>
                                  <TableCell>
                                    <Link href={`/admin/addSubject/${item.SubjectID}`}>Edit</Link>
                                  </TableCell>
                              </TableRow>
                            ))}
                            
                            
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[1,10, 25, 100]}
                        component="div"
                        count={totalPage}
                        rowsPerPage={rowsPerPage} 
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            
          </Container>
        </ThemeProvider>
    
    )
}